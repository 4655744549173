import './bootstrap';
// import '../css/app.css';
import vuetify from './plugins/vuetify'
import { createApp, h } from 'vue';
import { createInertiaApp, Link, Head } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createPinia } from 'pinia'
import translationPlugin from './plugins/translation'
import permissionPlugin from './plugins/permission';
import modulePlugin from './plugins/module';
import { defineAsyncComponent } from 'vue'
import AOS from 'aos'

import Toast from "vue-toastification";

import VueSplide from '@splidejs/vue-splide';
// import 'aos/dist/aos.css'
// import "vue-toastification/dist/index.css";
// import '@splidejs/vue-splide/css';
// import 'vue-tel-input/vue-tel-input.css';

const UserHere = defineAsyncComponent(() => import("@/Components/UserHere.vue"))
const LoadingButton = defineAsyncComponent(() => import("@/Components/Buttons/LoadingButton.vue"))
const MainLayout = defineAsyncComponent(() => import('./Layouts/MainLayout.vue'));
const LessonsLayout = defineAsyncComponent(() => import('./Layouts/LessonsLayout.vue'));
const AdminLayout = defineAsyncComponent(() => import('./Layouts/AdminLayout.vue'));
const Pagination = defineAsyncComponent(() => import("@/Components/Pagination.vue"));
const SearchFilter = defineAsyncComponent(() => import("@/Components/Input/SearchFilter.vue"));
const EditBtnVue = defineAsyncComponent(() => import("@/Components/Buttons/EditBtn.vue"));
const DeleteBtnVue = defineAsyncComponent(() => import("@/Components/Buttons/DeleteBtn.vue"));
const SwitchVue = defineAsyncComponent(() => import("@/Components/Switch/Switch.vue"));
const EmptyImage = defineAsyncComponent(() => import("@/Components/ImageHolder/EmptyImage.vue"));

const PageHeader = defineAsyncComponent(() => import("@/Components/LayoutItems/PageHeader.vue"));
const VerticalNavMenuGroup = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/NavComponents/VerticalNavMenuGroup.vue"));
const VerticalNavMenuLink = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/NavComponents/vertical-nav-menu-link/VerticalNavMenuLink.vue"));
const TableVue = defineAsyncComponent(() => import("@/Components/Table/Table.vue"));
const VueTelInput = defineAsyncComponent(() => import('vue-tel-input').then(m => m.VueTelInput));
const FileInput = defineAsyncComponent(() => import("@/Components/Input/FileInput.vue"));
const JetLabel = defineAsyncComponent(() => import("@/Components/Input/Label.vue"));
const UserLayout = defineAsyncComponent(() => import('@/Layouts/UserLayout.vue'));
const QuillInput = defineAsyncComponent(() => import("@/Components/Input/QuillInput.vue"));
const ToastOptions = {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true,
    position: "bottom-right",

};

const pinia = createPinia()
const appName = window.document.querySelector('meta[name="app-name"]').getAttribute('content');
createInertiaApp({

    title: (title) => `${title} | ${appName}`,
    // resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    resolve: async (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );
        page.then((module) => {
            if (name.includes('Lessons') && name.startsWith('Course/Lessons')) {
                module.default.layout = LessonsLayout
            }
            else if (name.includes('StartQuiz') && name.startsWith('Course/StartQuiz')) {
                module.default.layout = LessonsLayout
            }
            else if (name.includes('AllContent') && name.startsWith('Course/AllContent')) {
                module.default.layout = LessonsLayout
            }
            else if (module.default.layout === undefined && (name.startsWith('Web/Payment'))) {

            }
            else if (module.default.layout === undefined && (name.startsWith('Admin/') || name.startsWith('Client/'))) {
                module.default.layout = AdminLayout
            }

            else if (module.default.layout === undefined && !name.startsWith('Admin/')) {
                module.default.layout = MainLayout
            }

            // module.default.layout = module.default.layout || DefaultLayoutFile;
        });
        return page;


    },

    setup({ el, App, props, plugin }) {
        AOS.init();
        const myApp =
            createApp({ render: () => h(App, props) })
                .use(plugin)
                .use(Toast, ToastOptions)
                .use(VueSplide)
                .use(translationPlugin)
                .use(permissionPlugin)
                .use(modulePlugin)
                .use(vuetify)
                .use(pinia)
                .component("TableVue", TableVue)
                .component('VerticalNavMenuGroup', VerticalNavMenuGroup)
                .component('VerticalNavMenuLink', VerticalNavMenuLink)
                .component('Pagination', Pagination)
                .component('SearchFilter', SearchFilter)
                .component('EditBtnVue', EditBtnVue)
                .component('UserLayout', UserLayout)
                .component('DeleteBtnVue', DeleteBtnVue)
                .component('SwitchVue', SwitchVue)
                .component('EmptyImage', EmptyImage)
                .component('UserHere', UserHere)
                .component("VueTelInput", VueTelInput)
                .component('QuillInput', QuillInput)
                .component("loading-button", LoadingButton)
                .component('Link', Link)
                .component('JetLabel', JetLabel)
                .component('inertia-link', Link)
                .component('Head', Head)
                .component('PageHeader', PageHeader)
                .component('file-input', FileInput)
                .use(ZiggyVue);
        myApp.mount(el);
        delete el.dataset.page;
        return myApp
    },
    progress: {
        color: '#4B5563',
        delay: 250,


        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: true,
    },
});
